import Cookies from 'js-cookie'
import moment from 'moment'
import {
  BAD_REQUEST,
  FORBIDDEN_ERROR,
  GENERIC_ERROR_500,
  GENERIC_ERROR_503,
  LIVE_CAMPAIGN_HEALTH_CHECK,
  MEDIA_BENCHMARKS,
  METRICS_ALREADY_EXISTS,
  METRICS_FORMULA_ERROR,
  METRIC_ALREADY_EXISTS,
  NOT_FOUND_404,
  NO_DATA,
  POL_DOMAIN,
  SEARCH_ACTIVITY,
  SEARCH_KEYWORD_LEVEL,
  TEMPLATE_ALREADY_EXISTS,
  VIEW_ALREADY_EXISTS,
} from '../constants'
import { dateFilters, operatorOptions } from '../constants/dynamicDates'
interface Option {
  id: string
  label: string
  value: string
  group?: string
}

const polDomainRegex = /\.partnersonline\.com/g

export const reportTypes: Record<string, string> = {
  MODEL_ENABLEMENT: 'Model Enablement',
  PACING_PERFORMANCE: 'Pacing/Performance',
  CAMPAIGN_DAY_LEVEL: 'Campaign Day Level',
  CAMPAIGN_REPORT_LEVEL: 'Campaign Report Level',
  CAMPAIGN_TOTAL_LEVEL: 'Campaign Total',
  ITEM_PERFORMANCE: 'Item Performance',
  CAMPAIGN_ITEM_LEVEL: 'Campaign Item Level',
  ITEM_SALES: 'Item Sales',
  MEDIA_BENCHMARKS: 'Media Benchmarks',
  LIVE_CAMPAIGN_HEALTH_CHECK: 'Live Campaign Health Check',
  SEARCH_ACTIVITY: 'Search Activity',
  SEARCH_KEYWORD_LEVEL: 'Search Keyword Level',
}

const getValidSubReportTypes = (type: any, list: any) => {
  for (let i of list) {
    if (i.value === type) {
      return i.label
    }
  }
  return null
}

export const getReportTypeNames = (
  types: string[] = [],
  viewType: string,
  subReportList?: any,
) => {
  let names: string[] = []
  if (types?.length > 0) {
    types.forEach((type: string) => {
      if (viewType === 'report_sub_types' && subReportList?.length > 0) {
        const getReportType = getValidSubReportTypes(type, subReportList)
        if (getReportType) {
          names.push(getReportType)
        }
      } else if (viewType === 'report_types') {
        names.push(reportTypes[type] || '')
      }
    })
    return names.join(', ')
  } else {
    return '-'
  }
}

export const validateDates = (start: string = '', end: string = '') => {
  const hasStart = !!(start && start.length > 9)
  const hasEnd = !!(end && end.length > 9)
  let endDateError = hasStart && !hasEnd
  const startDateError = hasEnd && !hasStart
  const startDateErrorMessage = 'Please select start date'
  let endDateErrorMessage = 'Please select end date'
  if (hasStart && hasEnd) {
    endDateError = new Date(start).getTime() > new Date(end).getTime()
    endDateErrorMessage = 'End date can not be prior to start date'
    if (
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() >
      new Date(end).getTime()
    ) {
      endDateError =
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() >
        new Date(end).getTime()
      endDateErrorMessage = 'Please select future date'
    }
  }
  return {
    startDateError,
    startDateErrorMessage,
    endDateError,
    endDateErrorMessage,
  }
}

export const validateDateForOn = (start: string = '') => {
  const hasStart = !!(start && start.length > 9)
  let startDateError = !hasStart
  let startDateErrorMessage = 'Please select start date'
  if (hasStart) {
    if (
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() >
      new Date(start).getTime()
    ) {
      startDateError =
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() >
        new Date(start).getTime()
      startDateErrorMessage = 'Please select future date'
    }
  }
  return {
    startDateError,
    startDateErrorMessage,
  }
}

export const stringifyParameterValues = <T extends string>(
  parameters: Parameter<T>,
): Parameter<T> => {
  const transformedParameters = {} as Parameter<T>
  for (let parameterName in parameters) {
    try {
      transformedParameters[parameterName] = JSON.stringify(
        parameters[parameterName],
      )
    } catch {
      // non serializable params are ignored
    }
  }
  return transformedParameters
}

export const isViewPage = (path: string) => {
  let isViewPage = false
  if (path.includes('/view') && !path.includes('/views')) {
    isViewPage = true
  }
  return isViewPage
}

export const parseParameterValues = <T extends string>(
  parameters: Parameter<T>,
): Parameter<T> => {
  const transformedParameters = {} as Parameter<T>
  for (let parameterName in parameters) {
    try {
      transformedParameters[parameterName] = JSON.parse(
        parameters[parameterName],
      )
    } catch {
      // non serializable params are ignored
    }
  }
  return transformedParameters
}

export const parseToJson = (param?: string) => {
  let parsed
  try {
    if (param) {
      parsed = JSON.parse(param)
    }
  } catch {
    parsed = ''
  }
  return parsed
}

export const parseTemplateData = (data: any, type: TemplateType) => {
  if (type === 'TABULAR') {
    return data.map(
      ({
        primary_dimension,
        row_dimensions,
        ...rest
      }: {
        primary_dimension?: string
        row_dimensions?: string
      }) => {
        return {
          primary_dimension: parseToJson(primary_dimension),
          row_dimensions: parseToJson(row_dimensions),
          ...rest,
        }
      },
    )
  } else {
    return data
  }
}

const mimeType: Record<string, string> = {
  'application/octet-stream': '',
  'application/zip': '.zip',
  'application/csv': '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
}

export const saveFileAs = (data: any, fileName: string, mime: string) => {
  const extension = mimeType[mime]
  const name = `${fileName}${extension}`

  const blob = new Blob([data], { type: mime || 'application/octet-stream' })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, name)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', name)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}

/* View Name Validations */
export const isValidViewLength = (
  value: string,
  maxLength: number = 100,
  minLength: number = 3,
) => {
  const val = value && value.trim()
  const length = val?.length
  return length >= minLength && length <= maxLength
}

export const getTableAutocompleteOptions = (
  obj: Record<string, any>[],
  selectedTables: string[],
) => {
  let opt: Option[] = [] as Option[]
  if (obj) {
    for (const item of obj) {
      if (![SEARCH_ACTIVITY, SEARCH_KEYWORD_LEVEL].includes(item.value)) {
        if (!selectedTables.includes(item.value)) {
          opt.push({
            id: item.value,
            value: item.value,
            label: item.label,
            group: item.isInternalOnly ? 'Internal' : '',
          })
        }
      }
    }
  }
  return opt
}

export const isPOLDomain = (hostName = '') => {
  const polDomain = hostName.match(polDomainRegex)
  return polDomain != null ? true : false
}

export const isTargetDomain = (domainName: string) => {
  const targetDomainRegex = /^target\.com$/
  return domainName.match(targetDomainRegex) != null ? true : false
}

export const isValidViewNameSearch = (value: string) => {
  const validViewNamePattern = /^[ A-Za-z0-9+|!#$,:().&_-]*$/g
  const val = value && value.trim()
  return validViewNamePattern.test(val)
}

export const isValidViewName = (value: string) => {
  return isValidViewLength(value) && isValidViewNameSearch(value)
}

/* Metric Name Validations */
export const isValidNameSearch = (value: string) => {
  const validNamePattern = /^[ A-Za-z0-9+|!#$,:().&_-]*$/g
  const val = value && value.trim()
  return validNamePattern.test(val)
}

export const isValidNumber = (value: string) => {
  const validNamePattern = /^[ 0-9+|!#$,:().&_-]*$/g
  const val = value && value.trim()
  return validNamePattern.test(val)
}

export const isValidMetricName = (value: string) => {
  return isValidViewLength(value) && isValidNameSearch(value)
}

/* Table Name Validations */
export const isValidTableLength = (value: string) => {
  const val = value && value.trim()
  const length = val.length
  return length >= 3 && length <= 31
}
export const isValidTableName = (value: string) => {
  const validTableNamePattern = /^[ A-Za-z0-9+|,-._()]*$/g
  const val = value && value.trim()
  return isValidTableLength(val) && validTableNamePattern.test(val)
}

export const validateFilterField = (
  inputValue: string,
  type: string,
  decimal?: number,
) => {
  if (inputValue === '') {
    return false
  }
  if (!inputValue) {
    return true
  }
  const val = inputValue && inputValue.trim()
  const validViewNamePattern = /^[ A-Za-z0-9+|"'/\\/!#$,:().&_-]*$/g
  if (type === 'string') {
    return validViewNamePattern.test(val)
  } else if (type === 'number') {
    const decimalFormats: string[] = [
      'percentageWithDecimal',
      'currencyWithDecimal',
      'numberWithDecimal',
    ]
    const getDecimalNumber: number =
      decimal && decimalFormats.includes(decimal.toString()) ? 2 : 0
    if (getDecimalNumber > 0) {
      return /^[0-9]\d*(\.\d+)?$/.test(val)
    } else {
      return /^\d+$/.test(val)
    }
  } else if (type === 'date') {
    return !!inputValue
  }
}

export const validateFilterFieldAll = (
  inputArray: string[],
  type: string,
  decimal?: number,
) => {
  const results: boolean[] = []
  inputArray.forEach((inputValue) => {
    var result = inputValue.replace(/[\u200B-\u200D\uFEFF]/g, '')
    results.push(!!validateFilterField(result, type, decimal))
  })
  return !results.includes(false)
}

export const getSelectedOptions = (
  row: string[],
  rowOptions: SelectOption[],
) => {
  return row.reduce((acc, r: string) => {
    const option = rowOptions.find((options) => options.value === r)
    if (option) {
      acc.push(option)
    }
    return acc
  }, [] as SelectOption[])
}

export const getSelectedOption = (
  value: string,
  rowOptions: SelectOption[],
) => {
  const option = rowOptions.find((options) => options.value === value)
  return option
}

export const getFilterOptions = (obj: Record<string, any>[], name: string) => {
  let opt: Option[] = [] as Option[]
  if (obj) {
    const map = new Map()
    for (const item of obj) {
      if (!map.has(item[name])) {
        map.set(item[name], true) // set any value to Map
        opt.push({
          id: item[name],
          value: item[name],
          label: item[name],
        })
      }
    }
  }
  return opt
}

export const getCustomFilterOptions = (data: string[]) => {
  let opt: Option[] = [] as Option[]
  if (data) {
    const map = new Map()
    for (const item of data) {
      //check item as API is passing null value
      if (item && !map.has(item)) {
        map.set(item, true) // set any value to Map
        opt.push({
          id: item.toString(),
          value: item.toString(),
          label: item.toString(),
        })
      }
    }
  }
  return opt
}

export const transFormPresentation = <T, K extends string>(
  presentations: Presentation<T, K>[] = [],
) =>
  presentations.map((presentation) => ({
    ...presentation,
    parameters: stringifyParameterValues(presentation.parameters),
  }))
const errorsHash: Record<string, Record<number, string>> = {
  VIEW: {
    403: FORBIDDEN_ERROR,
    409: VIEW_ALREADY_EXISTS,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  DATA_TABLE: {
    403: FORBIDDEN_ERROR,
    404: NO_DATA,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  VIEWS_TEMPLATE_LOAD: {
    403: FORBIDDEN_ERROR,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  VIEW_RENAME_DUPLICATE: {
    400: BAD_REQUEST,
    403: FORBIDDEN_ERROR,
    404: NOT_FOUND_404,
    409: VIEW_ALREADY_EXISTS,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  TEMPLATE_RENAME_DUPLICATE: {
    400: BAD_REQUEST,
    403: FORBIDDEN_ERROR,
    404: NOT_FOUND_404,
    409: TEMPLATE_ALREADY_EXISTS,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  METRIC_RENAME_DUPLICATE: {
    400: BAD_REQUEST,
    403: FORBIDDEN_ERROR,
    404: NOT_FOUND_404,
    409: METRIC_ALREADY_EXISTS,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  DELETE_VIEW_TEMPLATE: {
    400: BAD_REQUEST,
    403: FORBIDDEN_ERROR,
    404: NOT_FOUND_404,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  DASHBOARD: {
    403: FORBIDDEN_ERROR,
    404: NO_DATA,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  METRICS_LOAD: {
    403: FORBIDDEN_ERROR,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  DIMENSIONS_LOAD: {
    403: FORBIDDEN_ERROR,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
  },
  METRICS_CREATE: {
    403: FORBIDDEN_ERROR,
    500: GENERIC_ERROR_500,
    503: GENERIC_ERROR_503,
    400: METRICS_FORMULA_ERROR,
    409: METRICS_ALREADY_EXISTS,
  },
}

export const getErrorMessage = (
  errors: any[],
  pageOrSection: string,
  showAllErrors: boolean = true,
): string => {
  let errorMessage = GENERIC_ERROR_500
  const viewErrors = errorsHash[pageOrSection]
  errorMessage = errors.reduce((message, error) => {
    const response = error?.response
    if (viewErrors[response?.status]) {
      //Error Message by status code
      message = viewErrors[response?.status]
    } else if (error?.response?.data?.message && showAllErrors) {
      //Error Message from response - taken only if showAllErros is true
      message = error?.response?.data?.message
    }
    return message
  }, '')
  return errorMessage
}

export const getReportType = (reportType: any) => {
  return reportTypes[reportType]
}

export const getTemplateType = (type: string, subType?: string) => {
  const reportType = reportTypes[type]
  return subType ? `${reportType} - ${reportTypes[subType]}` : `${reportType}`
}

export const refineNewLineChar = (obj: any) => {
  let finalObj: Record<string, string> = {}
  for (let key in obj) {
    finalObj[key] = obj[key]?.replace(/\\n/g, '\n')
  }
  return finalObj
}

export const setSelectedAccount = (obj: SelectOption) => {
  setToLocalStorage('selected_vendor', obj)
}
export const setLoggedInVendorDetails = (obj: SelectOption) => {
  setToLocalStorage('external_vendor_detail', obj)
}

export const getLoggedInVendorDetail = () => {
  return getFromLocalStorage('external_vendor_detail')
}

export const getSelectedAccount = () => {
  return getFromLocalStorage('selected_vendor')
}

export const getBpIdParam = (secondParam?: string) => {
  const vendorDetails = getSelectedAccount()
  let bpIdParam = ''
  if (secondParam) {
    bpIdParam = vendorDetails?.id ? `&bp_id=${vendorDetails?.id}` : ''
  } else {
    bpIdParam = vendorDetails?.id ? `?bp_id=${vendorDetails?.id}` : ''
  }

  return bpIdParam
}

export const getVendorName = () => {
  const vendorDetails = getSelectedAccount()
  return vendorDetails?.value || ''
}

export const getExternalVendorDetails = () => {
  return getFromLocalStorage('external_vendor_detail')
}

export const getCurrentBpId = () => {
  const vendorDetails = getSelectedAccount()
  return vendorDetails?.id || ''
}

export const getUserName = () => {
  const userDir = getFromLocalStorage('userDir')
  return userDir?.userName || ''
}

export const getLoggedInUserId = () => {
  const userDir = getFromLocalStorage('userDir')
  return userDir?.lanId || ''
}

export const getUserEmail = () => {
  const userDir = getFromLocalStorage('userDir')
  return userDir?.userEmail || ''
}

export const getCompanyName = () => {
  const userDir = getFromLocalStorage('userDir')
  return userDir?.companyName || ''
}

export const getFirstName = () => {
  const userDir = getFromLocalStorage('userDir')
  return userDir?.firstName || ''
}

export const isCreatedByInternalUser = (id2Dir: string) => {
  return id2Dir === 'ad' || id2Dir === 'corp'
}

export const isLoggedInAsInternalUser = () => {
  const userDir = getFromLocalStorage('userDir')
  return userDir ? userDir.value === 'ad' || userDir.value === 'corp' : false
}

export const isInternalUserMetricsAdmin = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  return (
    isLoggedInAsInternalUser() &&
    userADGroups?.value?.includes(apiConfig.metricsAdminGroup)
  )
}

export const isLoggedInAsTemplateAdmin = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  return userADGroups?.value?.includes(apiConfig.templateAdminGroup)
}

export const isAdmin = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  return (
    isLoggedInAsInternalUser() &&
    userADGroups?.value?.includes(apiConfig.rikAdminSettingsGroup)
  )
}

export const isRmsUser = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  const rmsADGroups = apiConfig?.rmsAccessGroups?.split(',')
  if (rmsADGroups && rmsADGroups.length > 0) {
    return rmsADGroups.some((rmsADGroup: any) =>
      userADGroups?.value?.includes(rmsADGroup?.toUpperCase()),
    )
  } else {
    return false
  }
}

export const isTargetPlusManagedServiceUser = (apiConfig: any) => {
  const userADGroups = getFromLocalStorage('userADGroups')
  const targetPlusADGroups = apiConfig?.targetPlusManagedService
    ?.split(',')
    .map((item: any) => item.trim())
  if (targetPlusADGroups?.length > 0) {
    return targetPlusADGroups.every((targetADGroup: any) =>
      userADGroups?.value?.includes(targetADGroup?.toUpperCase()),
    )
  } else {
    return false
  }
}

export const isRmsUserOnly = (user_type: string) => {
  return ['EXTERNAL_CRITEO', 'EXTERNAL_RMS'].includes(user_type)
}

export const setToLocalStorage = (name: string, obj: Object) => {
  window.localStorage.setItem(name, JSON.stringify(obj))
}

export const getFromLocalStorage = (item: string) => {
  const localData = window.localStorage.getItem(item) as string | ''
  try {
    return JSON.parse(localData)
  } catch (err) {
    return {}
  }
}

export const removeLocalStorageItems = (removables: string[]) => {
  removables.forEach((item) => {
    localStorage.removeItem(item)
  })
}

export const getDomainName = (): string => {
  const hostName = window?.location?.hostname
  return hostName?.split('.').slice(-2).join('.')
}

export const isPOLSessionActive = (userId: string): boolean => {
  if (getDomainName() === POL_DOMAIN) {
    const ssoSessionInfo = Cookies.get('SSOSESSIONINFO') || '' //oAuth will set this cookie
    if (ssoSessionInfo) {
      const ssoSessionObj = JSON.parse(window.atob(ssoSessionInfo)) //Decode session and parse to json object
      const { user, login_status } = ssoSessionObj
      const isActiveUser = user && login_status
      if (!isActiveUser) {
        return false
      }
      if (user !== userId) {
        // when selected company changed in POL
        removeLocalStorageItems([
          'id_token',
          'access_token',
          'userDir',
          'userADGroups',
        ])
        window.location.reload()
        return true
      }
    }
  }
  return true
}

export const getSortParams = (column: string, sortOption: Sort) => {
  const { sortOrder, field } = sortOption
  let order: 'ASC' | 'DESC' | '' = ''
  let col = ''
  if (column !== field || sortOrder === '') {
    order = 'ASC'
    col = column
  } else if (sortOrder === 'ASC') {
    order = 'DESC'
    col = column
  }
  return { order, col }
}

export const getSortText = (col: string, order: string) => {
  return col && order ? `${col}-${order}` : ''
}

export const getFormattedCSTDateTime = (
  dateRefreshed: string = new Date().toString(),
) => {
  return new Date(dateRefreshed).toLocaleString('en-US', {
    timeZone: 'America/Mexico_City',
    dateStyle: 'medium',
    timeStyle: 'long',
  })
}

export const getUserSpecificConfig = (env: any) => {
  const commonConfig = env['commonConfig']
  if (isLoggedInAsInternalUser()) {
    return Object.assign(commonConfig, env['internalUserConfig'])
  } else {
    return Object.assign(commonConfig, env['externalUserConfig'])
  }
}

export const getMonthlyOptions = () => {
  let options = []
  for (let i = 1; i <= 31; i++) {
    options.push({
      label: `Day ${i}`,
      value: i,
    })
  }
  return options
}

export const filterMetrics = (data: any, metricsData: Record<string, any>) => {
  const reportingType = data.reportingType
  const checkIfTableTypesMatch = (data: any) =>
    reportingType.every((type: string) =>
      data?.report_sub_types?.includes(type),
    )
  let options = []
  for (let i in metricsData) {
    if (checkIfTableTypesMatch(metricsData[i])) {
      options.push({
        id: metricsData[i]?.displayName || metricsData[i]?.metric_name,
        label: metricsData[i]?.displayName || metricsData[i]?.metric_name,
        value: i,
      })
    }
  }
  return options
}

export const getMetricsOptions = (
  metricsData: Record<string, any>,
  selectedTableType: any,
) => {
  let options: any = []
  if (
    selectedTableType.length === 1 &&
    (selectedTableType[0] === 'MEDIA_BENCHMARKS' ||
      selectedTableType[0] === 'LIVE_CAMPAIGN_HEALTH_CHECK')
  ) {
    metricsData = Object.values(metricsData).filter((metric: any) => {
      return metric.category !== 'Benchmarks' || !!metric.sub_category
    })
  } else {
    metricsData = Object.values(metricsData)
  }

  metricsData.forEach((metric: any) => {
    const { metric_name, field_name, sub_category } = metric
    const lable = `${metric_name}${sub_category ? ` [${sub_category}]` : ''}`
    options.push({
      id: lable,
      label: lable,
      value: field_name,
    })
  })
  return options
}

export const validateMinMax = (expr: any, char: string) => {
  let formulaArr = expr.split(' ').join('')
  let index = -1
  let regexp = /[min|max]+\([^\)]*\)(\.[^\)]*\))?/g

  while ((index = formulaArr.indexOf(char, index + 1)) !== -1) {
    if (index === -1) {
      index = 1
    }
    if (formulaArr[index + 3] !== '(') {
      return false
    }
    if (formulaArr[index + 4] === ')') {
      return false
    }
  }
  let matchFormula = formulaArr.match(regexp)
  if (matchFormula && matchFormula.length > 0) {
    for (let a = 0; a < matchFormula.length; a++) {
      let minMaxData = matchFormula[a].match(/\(([^)]+)\)/)
        ? matchFormula[a].match(/\(([^)]+)\)/)[1]
        : 0
      let funcArgs: number[] = minMaxData
        ? minMaxData.split(',').filter((s: any) => s.trim())
        : minMaxData
      if (funcArgs.length < 2) {
        return false
      }
    }
  }
  return true
}

export const isFormulaValid = (expr: string) => {
  let stack: string[] = []
  const specialChars = /[`!@#$%^&=[]{};':"\\|,.<>?~]/
  const opratorsArray = ['+', '-', '*', '/']
  let oprStack = []
  let maxExpr: any = true
  if (specialChars.test(expr)) {
    return false
  }
  if (expr.includes('min')) {
    maxExpr = validateMinMax(expr, 'min')
    if (!maxExpr) {
      return false
    }
  }
  if (expr.includes('max')) {
    maxExpr = validateMinMax(expr, 'max')
    if (!maxExpr) {
      return false
    }
  }
  if (expr.includes('(') || expr.includes(')')) {
    for (let i = 0; i <= expr.length; i++) {
      let x = expr[i]
      if (opratorsArray.includes(x)) {
        if (oprStack.length !== 0) {
          return false
        }
        oprStack.push(x)
      } else {
        if (x !== ' ') {
          oprStack = []
        }
      }
      if (x === ')' && stack.length === 0) {
        return false
      }
      if (x === '(') {
        stack.push(x)
      }
      if (x === ')') {
        stack.pop()
      }
    }
    return stack.length === 0
  } else {
    for (let i = 0; i <= expr.length; i++) {
      let x = expr[i]
      if (opratorsArray.includes(x)) {
        if (oprStack.length !== 0) {
          return false
        }
        oprStack.push(x)
      } else {
        oprStack = []
      }
    }
    return oprStack.length === 0
  }
}

export const getSortedCampaigns = (
  campaignsList: Record<string, any>[],
  sortOption: Sort,
  searchValue: string,
  type?: string,
  getDimensions?: Function,
) => {
  let finalCampaignList
  if (sortOption.field) {
    finalCampaignList = campaignsList.sort(function (a, b) {
      let currentElement =
        a[sortOption.field] !== undefined
          ? a[sortOption.field]
          : type === 'metirc'
            ? ''
            : a['flight_level_metrics'][sortOption.field]
      let nextElement =
        b[sortOption.field] !== undefined
          ? b[sortOption.field]
          : type === 'metirc'
            ? ''
            : b['flight_level_metrics'][sortOption.field]
      currentElement = Array.isArray(currentElement)
        ? currentElement.sort().toString()
        : currentElement
      nextElement = Array.isArray(nextElement)
        ? nextElement.sort().toString()
        : nextElement
      if (currentElement < nextElement) {
        return sortOption.sortOrder === 'ASC' ? -1 : 1
      }
      if (currentElement > nextElement) {
        return sortOption.sortOrder === 'ASC' ? 1 : -1
      }
      return 0
    })
  } else {
    finalCampaignList = campaignsList
  }

  const finalList = finalCampaignList.filter((item) => {
    let newItem = item
    for (const key in item) {
      if (key === 'report_sub_types' || key === 'report_types') {
        newItem = {
          ...newItem,
          [key]: getReportTypeNames(item[key], key),
        }
      } else if (key === 'report_type' || key === 'report_sub_type') {
        newItem = {
          ...newItem,
          [key]: getReportType(item[key]),
        }
      } else if (
        getDimensions &&
        (key === 'primary_dimension' || key === 'row_dimensions')
      ) {
        newItem = {
          ...newItem,
          [key]: getDimensions([item[key]] as string[]),
        }
      } else {
        newItem = {
          ...newItem,
          [key]: item[key],
        }
      }
    }
    const itemClone = Object.assign({}, newItem)
    let parsedValues = itemClone?.flight_level_metrics
      ? Object.values(itemClone?.flight_level_metrics)
      : []
    delete itemClone?.flight_level_metrics
    parsedValues = [...parsedValues, ...Object.values(itemClone)]
    return parsedValues
      .toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase())
  })
  return finalList
}

export const isNotValidBenchmarkDimensions = (
  selectedDimensions: string[],
  subReportType?: string,
) => {
  return (
    subReportType === MEDIA_BENCHMARKS &&
    mediaIdAndNameNotExists(selectedDimensions) &&
    flightNameOrProductNameNotExists(selectedDimensions) &&
    flightNameOrPLatformNameNotExists(selectedDimensions)
  )
}

export const invalidLiveHealthCheckDimensions = (
  selectedDimensions: string[],
  subReportType?: string,
) => {
  const mediaIdOrMediaNameOrCampaign = ['MEDIA_ID', 'MEDIA_NAME', 'FLIGHT_NAME']
  return (
    subReportType === LIVE_CAMPAIGN_HEALTH_CHECK &&
    !selectedDimensions.some((dimension) =>
      mediaIdOrMediaNameOrCampaign.includes(dimension),
    )
  )
}

export const mediaIdAndNameNotExists = (selectedDimensions: string[]) => {
  const MediaIdOrMediaName = ['MEDIA_ID', 'MEDIA_NAME']
  return !selectedDimensions.some((dimension) =>
    MediaIdOrMediaName.includes(dimension),
  )
}

export const flightNameOrProductNameNotExists = (
  selectedDimensions: string[],
) => {
  const FlightNameAndProductName = ['FLIGHT_NAME', 'PRODUCT_NAME']
  return !FlightNameAndProductName.every((dimension) =>
    selectedDimensions.includes(dimension),
  )
}

export const flightNameOrPLatformNameNotExists = (
  selectedDimensions: string[],
) => {
  const FlightNameAndPlatformName = ['FLIGHT_NAME', 'PLATFORM_NAME']
  return !FlightNameAndPlatformName.every((dimension) =>
    selectedDimensions.includes(dimension),
  )
}

export const isNonProdEnvironment = () => {
  const nonProd = ['wip', 'dev', 'perf', 'localhost']
  const pageURL = window.location.href
  return nonProd.some((env) => {
    return pageURL.includes(env)
  })
}

export const getdynamicDatesToDisplay = (field: string, obj: any) => {
  let opt = field
  if (!obj?.date_operator) {
    return opt
  }
  const { date_operator, date_selector, date_value, date_value2 } = obj
  let operatorVal = date_operator
  let dateSelectorVal = date_selector
  operatorOptions.forEach((option) => {
    if (option.value === date_operator) {
      operatorVal = option.label
      return
    }
  })
  dateFilters.forEach((option) => {
    if (option.value === date_selector) {
      dateSelectorVal = option.label
      return
    }
  })
  if (date_operator && date_selector !== 'CUSTOM') {
    opt = `${operatorVal} - ${dateSelectorVal}`
  }
  if (date_operator && date_selector === 'CUSTOM') {
    if (date_operator === 'BETWEEN') {
      opt = `${operatorVal} - ${date_value} - ${date_value2}`
    } else {
      opt = `${operatorVal} - ${date_value}`
    }
  }
  return opt
}

export const getdynamicDateDropdownLabel = (
  field: string,
  selectedDate: any,
) => {
  const { date_operator, date_selector, date_value, date_value2 } = selectedDate
  let displayLabel = field
  let dateSelectorVal = date_selector

  if (!date_operator) {
    return displayLabel
  }

  dateFilters.forEach((option) => {
    if (option.value === date_selector) {
      dateSelectorVal = option.label
      return
    }
  })
  if (date_operator && date_selector && date_selector !== 'CUSTOM') {
    displayLabel = `${dateSelectorVal}`
  } else {
    displayLabel = 'Please select'
  }
  if (date_operator && date_selector === 'CUSTOM') {
    if (date_operator === 'BETWEEN') {
      displayLabel = `${moment(date_value).format('YYYY-MM-DD')} - ${moment(
        date_value2,
      ).format('YYYY-MM-DD')}`
    } else {
      displayLabel = `${moment(date_value).format('YYYY-MM-DD')}`
    }
  }
  return displayLabel
}

export const groupBy = (array: any, baseKey: any) => {
  return array.reduce((obj: any, key: any) => {
    ;(obj[key[baseKey]] = obj[key[baseKey]] || []).push(key)
    return obj
  }, {})
}

export const removeDuplicates = (array: any) => {
  return array.filter(
    (item: any, index: number) => array.indexOf(item) === index,
  )
}

export const isCrossAdvertiserAccount = (apiConfig: ApiConfig) => {
  const { id } = getSelectedAccount()
    ? getSelectedAccount()
    : getExternalVendorDetails()
  return id === apiConfig?.crossAdvertiser?.carId
}
